:root {
    --f7-theme-color: #ab283c !important;
    --f7-theme-color-rgb: 171, 40, 60 !important;
    --f7-theme-color-shade: #8a1020 !important;
    --f7-theme-color-tint: #cc1830 !important;
    --f7-font-family: 'Montserrat', sans-serif !important;

    --text-color: #545454 !important;
    --f7-block-title-medium-text-color: var(--text-color) !important;
    --f7-block-title-medium-text-transform: uppercase !important;
    --f7-block-title-medium-font-weight: 300 !important;
    --f7-block-title-medium-font-size: 1.2rem !important;
    --f7-block-title-white-space: break-word !important;

    --f7-page-master-width: calc(100vw / 3) !important;
    --f7-page-master-max-width: 430px !important;
    --f7-page-master-border-width: 0 !important;

    --f7-fab-bg-color: #f1f3f4;

    --content-side-padding: 1rem;
    --border-radius: 1.5rem;

    --top-bar: 5rem;

    --small-width: 375px;
}

/* styling native f7 components */
.page {
    font-size: 14px;
    /*position: unset!important;*/
}

.page-content {
    overflow-x: hidden;
}

.block-title {
    margin-left: 0;
}

.page-current {
    background-size: cover;
}

.view-master-detail .page-master, .view-master-detail .navbar-master {
    min-width: calc(100% - var(--f7-page-master-width));
    width: 100%;
    max-width: calc(100% - var(--f7-page-master-max-width));
}

.view-master-detail .page-master-detail, .view-master-detail .navbar-master-detail {
    width: var(--f7-page-master-width);
    max-width: var(--f7-page-master-max-width);
    right: 0;
    left: auto;
}

audio {
    width: 100%;
}

p {
    font-size: var(--font-size);
    color: var(--text-color);
    line-height: 1.3rem;
}